
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { Admission } from './components/AdmissionPage/Admission';

export const App = () => (
  <Router>
    <Routes>
      <Route path="*" element={<Navigate to="/admission" />} />
      <Route path="/admission" element={<Admission />} />
    </Routes>
  </Router>
)

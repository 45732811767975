import { extendTheme } from "@chakra-ui/react"
import { theme } from '@chakra-ui/pro-theme'

import '@fontsource-variable/open-sans'
import '@fontsource-variable/spline-sans'

const proTheme = extendTheme(theme)
const extendedConfig = {
    colors: { ...proTheme.colors, brand: proTheme.colors.blue },
}
export const myTheme = extendTheme(extendedConfig, proTheme)
import React, { useState, useEffect } from "react";
import {
    Text,
    Checkbox,
    Box,
    Select,
    VStack,
    Input,
    Tag,
    TagLabel,
    TagCloseButton,
    RadioGroup,
    Radio,
    HStack,
    Stack,
    Button,
    List,
    ListItem,
    ListIcon,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { AdmissionData, columnMap } from "./data";
// Assuming AdmissionData and columnMap are correctly imported

const locationFilter: { [key in keyof AdmissionData]?: string } = {
    province: "string",
    //city: "string",
};

const universityFilter: { [key in keyof AdmissionData]?: string } = {
    study_years: "number",
    is_major_college: "boolean",
    is_technical_college: "boolean",
    first_early_admissions: "boolean",
    second_early_admissions: "boolean",
    regular_admissions: "boolean",
};

const TestFilter: { [key in keyof AdmissionData]?: string } = {
    midi_composition: "boolean",
    performance: "boolean",
    self_composition: "boolean",
    portfolio: "boolean",
    interview: "boolean",
    sync_composition: "boolean",
    sight_reading: "boolean",
    practical_harmony: "boolean",
    traditional_harmony: "boolean",
    sight_singing_ear_training: "boolean",
    sound_engineering: "boolean",
    school_records: "boolean",
    activity_records: "boolean",
    video_submission: "boolean",
};

// String이면 Select
// Number면 NumberInput
// Boolean이면 Checkbox

interface LocationInterface {
    province: string;
    //city: string;
}

interface Props {
    data: AdmissionData[];
    tableFilter: Partial<AdmissionData>;
    setTableFilter: React.Dispatch<React.SetStateAction<Partial<AdmissionData>>>;
}

const clearFilter = (setFilter: React.Dispatch<React.SetStateAction<Partial<AdmissionData>>>,
    setSelectedUniversities: React.Dispatch<React.SetStateAction<string[]>>,
    setSelectedLocations: React.Dispatch<React.SetStateAction<LocationInterface[]>>
) => {
    setFilter({});
    setSelectedUniversities([]);
    setSelectedLocations([]);
    console.log("Clear Filter");
};

export function Filter(props: Props) {
    const { data, tableFilter, setTableFilter } = props;

    // 필터 입력값 변경 함수
    const handleFilterChange = (key: string, value: any) => {
        setTableFilter((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const universities = Array.from(new Set(data.map((item) => item.university)));
    const [searchTerm, setSearchTerm] = useState(""); // 사용자의 입력 값을 저장
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(""); // 디바운스 후 값을 저장
    const [filteredUniversities, setFilteredUniversities] = useState<string[]>([]); // 검색 결과를 저장
    const [selectedUniversities, setSelectedUniversities] = useState<string[]>(
        Array.isArray(tableFilter.university) ? tableFilter.university : []
    );// 선택된 대학교를 저장
    const [selectedLocations, setSelectedLocations] = useState<LocationInterface[]>(
        Array.isArray(tableFilter.location) ? tableFilter.location : []
    );// 선택된 지역을 저장

    useEffect(() => {
        // 입력 후 일정 시간 대기 후 검색어를 업데이트
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm); // 최종적으로 debounce된 값을 저장
        }, 500); // 300ms 대기 시간

        return () => {
            clearTimeout(handler); // 이전 타이머를 제거하여 중복 호출 방지
        };
    }, [searchTerm]); // searchTerm이 변경될 때마다 실행

    useEffect(() => {
        if (debouncedSearchTerm) {
            // 디바운스 후 작업 수행
            setFilteredUniversities(
                universities.filter((item) =>
                    item.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
                )
            );
        } else {
            setFilteredUniversities([]);
        }
    }, [debouncedSearchTerm]);

    // Handle selection of a university
    const handleSelectUniversity = (university: string) => {
        if (!selectedUniversities.includes(university)) {
            const newSelectedUniversities = [...selectedUniversities, university];
            setSelectedUniversities(newSelectedUniversities);
            handleFilterChange("university", newSelectedUniversities);
        }
        setSearchTerm(""); // Clear the input field
    };

    // Handle removal of a selected university
    const handleRemoveUniversity = (university: string) => {
        const newSelectedUniversities = selectedUniversities.filter((item) => item !== university);
        setSelectedUniversities(newSelectedUniversities);
        handleFilterChange("university", newSelectedUniversities);
    };

    const handleSelectLocation = (location: LocationInterface) => {
        if (!selectedLocations.some((item) => item.province === location.province)) {
            const newSelectedLocations = [...selectedLocations, location];
            setSelectedLocations(newSelectedLocations);
            handleFilterChange("location", newSelectedLocations);
        }
    }

    // Handle removal of a selected location
    const handleRemoveLocation = (province: string) => {
        // `selectedLocations`는 배열이므로, 배열에서 해당 도시를 필터링하여 제거
        const newSelectedLocations = selectedLocations.filter((item) => item.province !== province);
        setSelectedLocations(newSelectedLocations);
        handleFilterChange("location", newSelectedLocations);
    };

    return (
        <VStack spacing={4} align="start">
            <Stack spacing={4} direction={{ "base": "column", "md": "row" }}>
                <VStack align="start">
                    <HStack>
                        <Text as="h3" size="sm" fontWeight="bold" whiteSpace="nowrap" mr="3">
                            지역 필터링
                        </Text>
                        {MakeFilter(data,
                            locationFilter,
                            tableFilter,
                            handleFilterChange,
                            handleSelectLocation)}
                    </HStack>
                    <HStack wrap="wrap">
                        {selectedLocations.map((selectedLocation) => (
                            <Tag
                                size="sm"
                                borderRadius="full"
                                variant="solid"
                                colorScheme="gray"
                                key={selectedLocation.province}
                            >
                                <TagLabel>{selectedLocation.province}</TagLabel>
                                <TagCloseButton onClick={() => handleRemoveLocation(selectedLocation.province)} />
                            </Tag>
                        ))}
                    </HStack>
                </VStack>
                <HStack align="start">
                    <VStack align="start">
                        <HStack>
                            <Text as="h3" size="sm" fontWeight="bold" whiteSpace="nowrap" mr="3">
                                학교 검색
                            </Text>
                            <Box w="250px" position="relative">
                                <Input
                                    size="sm"
                                    type="text"
                                    rounded={0}
                                    placeholder="대학교 검색"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                {filteredUniversities.length > 0 && (
                                    <Box
                                        position="absolute"
                                        top="100%"
                                        left={0}
                                        right={0}
                                        bg="white"
                                        border="1px solid #ccc"
                                        zIndex={1}
                                        maxHeight="200px"
                                        overflowY="auto"
                                    >
                                        <List spacing={1}>
                                            {filteredUniversities.map((university) => (
                                                <ListItem
                                                    key={university}
                                                    padding="8px"
                                                    _hover={{ backgroundColor: "gray.100" }}
                                                    cursor="pointer"
                                                    onClick={() => handleSelectUniversity(university)}
                                                >
                                                    <ListIcon as={SearchIcon} color="blue.500" />
                                                    {university}
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Box>
                                )}
                            </Box>
                        </HStack>
                        <HStack wrap="wrap">
                            {selectedUniversities.map((item) => (
                                <Tag
                                    size="sm"
                                    borderRadius="full"
                                    variant="solid"
                                    colorScheme="gray"
                                    key={item}
                                >
                                    <TagLabel>{item}</TagLabel>
                                    <TagCloseButton onClick={() => handleRemoveUniversity(item)} />
                                </Tag>
                            ))}
                        </HStack>
                    </VStack>
                </HStack>
                <Button
                    onClick={() => clearFilter(setTableFilter, setSelectedUniversities, setSelectedLocations)}
                    display={{ "base": "none", "lg": "block" }}>필터 초기화
                </Button>
            </Stack>
            <HStack wrap="wrap">
                <Text as="h3" size="sm" fontWeight="bold" whiteSpace="nowrap" mr="3">
                    학교 필터링
                </Text>
                {MakeFilter(data,
                    universityFilter,
                    tableFilter,
                    handleFilterChange,
                    handleSelectLocation)}
            </HStack>
            <HStack wrap="wrap">
                <Text as="h3" size="sm" fontWeight="bold" whiteSpace="nowrap" mr="3">
                    입시과목 필터링
                </Text>
                <HStack wrap="wrap">
                    {MakeFilter(data,
                        TestFilter,
                        tableFilter,
                        handleFilterChange,
                        handleSelectLocation)}
                </HStack>
            </HStack>
            <Button
                onClick={() => clearFilter(setTableFilter, setSelectedUniversities, setSelectedLocations)}
                display={{ "base": "block", "lg": "none" }}>필터 초기화
            </Button>
        </VStack>
    );
}

function MakeFilter(
    data: AdmissionData[],
    filter: { [key in keyof AdmissionData]?: string },
    tableFilter: Partial<AdmissionData>,
    handleFilterChange: (key: string, value: any) => void,
    handleSelectLocation: (location: LocationInterface) => void,
) {
    const locationCombination = data.map((item) => {
        return ({ "province": item.province })
    });
    const provinceList = Array.from(new Set(locationCombination.map((item) => item.province)));
    // ㄱㄴㄷ 순으로 정렬
    provinceList.sort((a, b) => a.localeCompare(b));
    return Object.entries(filter).map(([key, valueType]) => {
        let admissionKey = key as keyof AdmissionData;
        if (admissionKey === "province" || admissionKey === "city") {
            let optionList: string[] = [];
            let value = "";

            if (admissionKey == "province") {
                optionList = provinceList;
            }
            else {
                return null
            }
            return (
                <Select
                    key={admissionKey}
                    name={admissionKey}
                    value={value}
                    onChange={(e) => {
                        const selectedValue = e.target.value;
                        handleSelectLocation({ province: selectedValue });
                    }}
                    size="sm"
                    placeholder={`${columnMap[admissionKey] || admissionKey} 선택`}
                >
                    {optionList.map((item) => {
                        if (typeof item !== "string") return null;
                        return (
                            <option key={item} value={item}>
                                {item}
                            </option>
                        );
                    })}
                </Select>
            );
        } else if (key === "study_years") {
            let value: string | undefined = tableFilter[admissionKey] as string;
            if (value !== "2,3" && value !== "4") {
                value = "";
            }
            return (
                <HStack key={admissionKey} mr={3}>
                    <RadioGroup
                        name={admissionKey}
                        value={value || ""}
                        onChange={(e) => {
                            handleFilterChange(admissionKey, e || undefined);
                        }}
                        size="sm"
                    >
                        <Stack direction='row'>
                            <Radio value='4' size={{ base: "md" }}>4년제</Radio>
                            <Radio value='2,3' size={{ base: "md" }}>2, 3년제</Radio>
                        </Stack>
                    </RadioGroup>
                </HStack>
            );
        } else if (valueType === "boolean") {
            let isChecked = false;
            if (typeof tableFilter[admissionKey] === "boolean") {
                isChecked = tableFilter[admissionKey] as boolean;
            }
            return (
                <Checkbox
                    key={admissionKey}
                    name={admissionKey}
                    isChecked={isChecked}
                    onChange={(e) => handleFilterChange(admissionKey, e.target.checked)}
                >
                    {columnMap[admissionKey] || admissionKey}
                </Checkbox>
            );
        }
        return null;
    });
}

import {
    Box,
    HStack,
    Link,
} from '@chakra-ui/react'

import { Logo } from '../../assets/Logo'

export function NavBar() {
    return (
        <Box as="section" w="full" mb={8}>
            <Box borderBottomWidth="1px" bg="bg.surface" w="100vw">
                <HStack py="6" px="6">
                    <Link href="/">
                        <Logo />
                    </Link>
                </HStack>
            </Box>
        </Box>
    )
}
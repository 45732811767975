import { Box, VStack, Heading } from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { NavBar } from "../Common/NavBar";
import { MemberTable } from "./Table";
import { AdmissionData, AdmissionDataExample } from "./data";

import { Filter } from "./Filter";

export function Admission() {
    const [data, setData] = useState<AdmissionData[]>([]);
    const [tableFilter, setTableFilter] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("/api/admission/", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    }
                });

                if (!response.ok) {
                    throw new Error(`서버 상태가 이상합니다! ${response.status}`);
                }

                const jsonData = await response.json();
                console.log(jsonData);
                setData(jsonData);
            } catch (error) {
                console.error(`에러 발생 : ${error}`);
                setData(AdmissionDataExample);
            }
        };
        fetchData();
    }, []);
    return (
        <Box h="full" w="full">
            <NavBar />
            <VStack align="left" px={{"base": "3", "lg": "6"}}>
                {
                    data.length === 0 ?
                        <Heading as="h2" size={{ base: "md", md: "lg" }}>입시 정보</Heading> :
                        <Heading as="h2" size={{ base: "md", md: "lg" }}>{data[0]?.year || ""}년 입시 ({data[0]?.year + 1 || ""} 입학)</Heading>
                }
                <Box height={{ base: "3", md: "6" }}></Box>
                <Filter tableFilter={tableFilter} setTableFilter={setTableFilter} data={data}></Filter>
                <Box height={{ base: "1", md: "3" }}></Box>
                <MemberTable data={data} tableFilter={tableFilter} />
            </VStack>
        </Box>
    );
}
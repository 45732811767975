import {
    Table,
    TableProps,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Badge,
    Text,
    Box,
    Link,
} from '@chakra-ui/react';
import { AdmissionData, columnMap } from './data';

interface Props {
    data: AdmissionData[];
    tableFilter: Record<string, any>;
    tableProps?: TableProps;
}

const excludeRowByTableFilter = (
    data: AdmissionData,
    tableFilter: Record<string, any>
): boolean => {
    if (!tableFilter) {
        return false;
    }
    return Object.entries(tableFilter).some(([key, value]) => {
        if (value === null || value === undefined || value === '') {
            return false;
        }

        if (typeof value === 'string') {
            let splittedValue = value.split(',');
            if (splittedValue.length > 1) {
                return !splittedValue.includes(String(data[key as keyof AdmissionData]));
            }
            return String(data[key as keyof AdmissionData]) !== String(value);
        }
        if (typeof value === 'number') {
            return data[key as keyof AdmissionData] !== value;
        }
        if (typeof value === 'boolean') {
            return data[key as keyof AdmissionData] !== value;
        }
        if (Array.isArray(value)) {
            if (key === 'location') {
                return !value.some((location: { province: string; city: string }) => {
                    return (
                        location.province === data.province
                    );
                });
            }
            return !value.includes(data[key as keyof AdmissionData]);
        }
    });
};

const generateUniqueKey = (row: AdmissionData): string => {
    // Assuming 'id' is unique; if not, combine multiple properties
    return row.id ? String(row.id) : `${row.university}-${row.year}`;
};

export const MemberTable = ({ data, tableFilter, tableProps }: Props) => {
    if (!data || data.length === 0) {
        return <Text>데이터가 없습니다.</Text>;
    }

    const columnKeys = Object.keys(data[0]) as (keyof AdmissionData)[];
    const excludeKeys = ['id', 'year', 'created_at', 'updated_at', 'province', 'city', 'university_url'];
    const percentKeys = ['school_records_percentage', 'activity_records_percentage'];

    const displayedColumns = columnKeys.filter((key) => !excludeKeys.includes(key));

    // Filter the data before rendering
    const filteredData = data.filter((row) => !excludeRowByTableFilter(row, tableFilter));

    return (
        <Box overflowX="auto" overflowY="auto" maxHeight={{"base": "60vh", "lg": "75vh"}}>
            <Table {...tableProps}>
                <Thead>
                    <Tr>
                        {displayedColumns.map((key) => {
                            const isUniversityColumn = key === 'university';
                            return (
                                <Th
                                    key={key}
                                    position="sticky"
                                    top={0}
                                    left={isUniversityColumn ? 0 : undefined}
                                    zIndex={isUniversityColumn ? 3 : 2}
                                    background="white"
                                    boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
                                    borderBottom="2px solid #E2E8F0"
                                >
                                    {columnMap[key] || key}
                                </Th>
                            );
                        })}
                    </Tr>
                </Thead>
                <Tbody>
                    {filteredData.map((row) => (
                        <Tr key={generateUniqueKey(row)}>
                            {displayedColumns.map((key) => {
                                const isUniversityColumn = key === 'university';
                                return (
                                    isUniversityColumn ? (
                                        <Td
                                            key={`${generateUniqueKey(row)}-${key}`}
                                            position="sticky"
                                            left={0}
                                            zIndex={1}
                                            background="white"
                                            boxShadow="1px 0 2px rgba(0, 0, 0, 0.1)"
                                        >
                                            {
                                                row.university_url ? (
                                                    <Link href={row.university_url} isExternal>
                                                        {row[key]}
                                                    </Link>
                                                ) : (
                                                    <Text>{row[key]}</Text>
                                                )
                                            }
                                        </Td>
                                    ) : (
                                        <Td
                                            key={`${generateUniqueKey(row)}-${key}`}
                                        >
                                            {row[key] === null ? (
                                                <Text color="gray.500">-</Text>
                                            ) : typeof row[key] === 'boolean' ? (
                                                row[key] ? (
                                                    <Badge colorScheme="green">O</Badge>
                                                ) : (
                                                    <Text>-</Text>
                                                )
                                            ) : percentKeys.includes(key) ? (
                                                <Text>{row[key]}%</Text>
                                            ) : (
                                                <Text>{String(row[key])}</Text>
                                            )}
                                        </Td>
                                    )
                                );
                            })}
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
};

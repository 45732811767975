export const columnMap: { [key in keyof AdmissionData]?: string } = {
    "year": "연도",
    "location": "지역",
    "province": "도/특별시",
    "city": "시/군/구",
    "university": "학교",
    "study_years": "년제",
    "is_major_college": "전공대",
    "is_technical_college": "전문대",
    "regular_admissions": "정시",
    "first_early_admissions": "1차 수시",
    "second_early_admissions": "2차 수시",
    "major": "과/전공",
    "notes": "비고",
    "midi_composition": "미디자작곡",
    "performance": "연주",
    "self_composition": "자작곡",
    "portfolio": "포트폴리오",
    "interview": "면접",
    "sync_composition": "동기작곡",
    "sight_reading": "초견(스,코)",
    "practical_harmony": "실용화성",
    "traditional_harmony": "전통화성",
    "sight_singing_ear_training": "시창/청음",
    "sound_engineering": "음향",
    "school_records": "학생부교과",
    "school_records_percentage": "비율",
    "activity_records": "생기부",
    "activity_records_percentage": "비율",
    "video_submission": "영상제출",
}


interface locationFilter {
    "province": string,
    "city": string,
}

export interface AdmissionFilter {
    "year": number,
    "location": locationFilter[],
    "university": string[],
    "study_years": number,
    "is_major_college": boolean,
    "is_technical_college": boolean,
    "regular_admissions": boolean,
    "first_early_admissions": boolean,
    "second_early_admissions": boolean,
    "major": string,
    "notes": string
    "midi_composition": boolean,
    "performance": boolean,
    "self_composition": boolean,
    "portfolio": boolean,
    "interview": boolean,
    "sync_composition": boolean,
    "sight_reading": boolean,
    "practical_harmony": boolean,
    "traditional_harmony": boolean,
    "sight_singing_ear_training": boolean,
    "sound_engineering": boolean,
    "school_records": boolean,
    "school_records_percentage": number | null,
    "activity_records": boolean,
    "activity_records_percentage": number | null,
    "video_submission": boolean,
    "created_at": string,
    "updated_at": string,
}


export interface AdmissionData {
    "id": number
    "year": number,
    "location": string,
    "province": string,
    "city": string,
    "university": string
    "university_url"? : string,
    "study_years": number,
    "is_major_college": boolean,
    "is_technical_college": boolean,
    "regular_admissions": boolean,
    "first_early_admissions": boolean,
    "second_early_admissions": boolean,
    "major": string,
    "notes": string
    "midi_composition": boolean,
    "performance": boolean,
    "self_composition": boolean,
    "portfolio": boolean,
    "interview": boolean,
    "sync_composition": boolean,
    "sight_reading": boolean,
    "practical_harmony": boolean,
    "traditional_harmony": boolean,
    "sight_singing_ear_training": boolean,
    "sound_engineering": boolean,
    "school_records": boolean,
    "school_records_percentage": number | null,
    "activity_records": boolean,
    "activity_records_percentage": number | null,
    "video_submission": boolean,
    "created_at": string,
    "updated_at": string,
}

const exampleData: AdmissionData = {
    "id": 1,
    "year": 2024,
    "university": "서울대학교",
    "university_url": "https://www.snu.ac.kr/",
    "location": "경기도 (화성시)",
    "province": "경기도",
    "city": "화성시",
    "study_years": 4,
    "is_major_college": false,
    "is_technical_college": false,
    "major": "기계공학과",
    "notes": "",
    "regular_admissions": true,
    "first_early_admissions": false,
    "second_early_admissions": false,
    "midi_composition": false,
    "performance": false,
    "self_composition": false,
    "portfolio": false,
    "interview": false,
    "sync_composition": false,
    "sight_reading": false,
    "practical_harmony": false,
    "traditional_harmony": false,
    "sight_singing_ear_training": false,
    "sound_engineering": false,
    "school_records": false,
    "school_records_percentage": null,
    "activity_records": false,
    "activity_records_percentage": null,
    "video_submission": false,
    "created_at": "2024-11-20T10:35:04.927463Z",
    "updated_at": "2024-11-20T10:35:04.927499Z"
}

const exampleData2: AdmissionData = {
    "id": 1,
    "year": 2024,
    "university": "연세대학교",
    "location": "경기도 (용인시)",
    "province": "경기도",
    "city": "용인시",
    "study_years": 4,
    "is_major_college": false,
    "is_technical_college": false,
    "major": "기계공학과",
    "notes": "",
    "regular_admissions": true,
    "first_early_admissions": false,
    "second_early_admissions": false,
    "midi_composition": false,
    "performance": false,
    "self_composition": false,
    "portfolio": false,
    "interview": false,
    "sync_composition": false,
    "sight_reading": false,
    "practical_harmony": false,
    "traditional_harmony": false,
    "sight_singing_ear_training": false,
    "sound_engineering": false,
    "school_records": false,
    "school_records_percentage": null,
    "activity_records": false,
    "activity_records_percentage": null,
    "video_submission": false,
    "created_at": "2024-11-20T10:35:04.927463Z",
    "updated_at": "2024-11-20T10:35:04.927499Z"
}


const exampleData3: AdmissionData = {
    "id": 1,
    "year": 2024,
    "university": "고려대학교",
    "location": "충청남도 (단양군)",
    "province": "충청남도",
    "city": "단양군",
    "study_years": 4,
    "is_major_college": false,
    "is_technical_college": false,
    "major": "기계공학과",
    "notes": "",
    "regular_admissions": true,
    "first_early_admissions": false,
    "second_early_admissions": false,
    "midi_composition": false,
    "performance": false,
    "self_composition": false,
    "portfolio": false,
    "interview": false,
    "sync_composition": false,
    "sight_reading": false,
    "practical_harmony": false,
    "traditional_harmony": false,
    "sight_singing_ear_training": false,
    "sound_engineering": false,
    "school_records": false,
    "school_records_percentage": null,
    "activity_records": false,
    "activity_records_percentage": null,
    "video_submission": false,
    "created_at": "2024-11-20T10:35:04.927463Z",
    "updated_at": "2024-11-20T10:35:04.927499Z"
}


const exampleData4: AdmissionData = {
    "id": 1,
    "year": 2024,
    "university": "고구려대학교",
    "location": "함경북도 (함흥시)",
    "province": "함경북도",
    "city": "함흥시",
    "study_years": 3,
    "is_major_college": false,
    "is_technical_college": false,
    "major": "기계공학과",
    "notes": "",
    "regular_admissions": true,
    "first_early_admissions": false,
    "second_early_admissions": false,
    "midi_composition": false,
    "performance": false,
    "self_composition": false,
    "portfolio": false,
    "interview": false,
    "sync_composition": false,
    "sight_reading": false,
    "practical_harmony": false,
    "traditional_harmony": false,
    "sight_singing_ear_training": false,
    "sound_engineering": false,
    "school_records": false,
    "school_records_percentage": null,
    "activity_records": false,
    "activity_records_percentage": null,
    "video_submission": false,
    "created_at": "2024-11-20T10:35:04.927463Z",
    "updated_at": "2024-11-20T10:35:04.927499Z"
}


export const AdmissionDataExample: AdmissionData[] = []

for (let i = 0; i < 10; i++) {
    AdmissionDataExample.push(exampleData);
}

for (let i = 0; i < 10; i++) {
    AdmissionDataExample.push(exampleData2);
}

for (let i = 0; i < 10; i++) {
    AdmissionDataExample.push(exampleData3);
}

for (let i = 0; i < 10; i++) {
    AdmissionDataExample.push(exampleData4);
}